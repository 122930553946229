import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  GetAllAccountStartupsDocument,
  SendStartupInvitationMailQueryDocument,
  GetSingleStartupItemDocument,
  GetStartupWithLogoDataQueryDocument,
  CreateNewStartupMutationDocument,
  StartupDataInput,
  CheckStartupApplyLinkValidityQueryDocument,
  GetStartupNameDocument,
} from '@codenteam/portal/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvestorHubService {
  constructor(private apollo: Apollo) {
    console.log('constructing InvestorHubService service');
  }

  getAllStartupsForSpecificAccount() {
    return this.apollo
      .query({
        query: GetAllAccountStartupsDocument,
      })
      .pipe(map((response) => response.data.getAllStartupsForAccount));
  }

  getStartupItem(startupUuid: string) {
    return this.apollo
      .query({
        query: GetSingleStartupItemDocument,
        variables: {
          startupUuid: startupUuid,
        },
      })
      .pipe(map((response) => response.data.getStartupItem));
  }

  sendStartupInvitationMail(
    email: string,
    isCopy: boolean,
    instantInvite: boolean
  ) {
    return this.apollo
      .query({
        query: SendStartupInvitationMailQueryDocument,
        variables: {
          email,
          isCopy,
          instantInvite,
        },
      })
      .pipe(map((response) => response.data?.sendStartupInvitationMail));
  }

  getStartupDataWithUploadLogoUrl(accountUuid: string, startupUuid?: string) {
    return this.apollo
      .query({
        query: GetStartupWithLogoDataQueryDocument,
        variables: {
          accountUuid,
          startupUuid,
        },
      })
      .pipe(map((response) => response.data?.getStartupWithLogoData));
  }

  createNewStartup(
    startupData: StartupDataInput,
    accountUuid: string,
    instantInvite: boolean,
    userId: number
  ) {
    return this.apollo
      .mutate({
        mutation: CreateNewStartupMutationDocument,
        variables: {
          StartupDataInput: startupData,
          accountUuid,
          instantInvite,
          userId,
        },
      })
      .pipe(map((response) => response.data?.createNewStartup));
  }

  checkStartupApplyLinkValidity(token: string) {
    return this.apollo
      .query({
        query: CheckStartupApplyLinkValidityQueryDocument,
        variables: {
          token,
        },
      })
      .pipe(map((response) => response.data?.checkStartupApplyLinkValidity));
  }
  getStartupName(startupUuid: string) {
    return this.apollo
      .query({
        query: GetStartupNameDocument,
        variables: {
          startupUuid,
        },
      })
      .pipe(map((response) => response.data?.getStartupItem));
  }
}
